import { useMsal } from '@azure/msal-react';
import { loginRequest } from './msalConfig';
import { useEffect, useState, useRef } from 'react';
import axios from 'axios';

const useAuth = () => {
  const { instance, accounts, inProgress } = useMsal();
  const [token, setToken] = useState(null);
  const [error, setError] = useState('');
  const [interactionInProgress, setInteractionInProgress] = useState(false);
  const loginAttempted = useRef(false);
  const popupClosed = useRef(false);
  const [accountsMsal, setAccountsMsal] = useState('')
  const [token_email, setTokenEmail] = useState('')

  useEffect(() => {
    const handleLogin = async () => {
      if (interactionInProgress || inProgress !== 'none' || loginAttempted.current || popupClosed.current) return;
      setInteractionInProgress(true);
      try {
        const account = accounts[0];
        if (accounts.length > 0) {
          // Set the active account if not already set
          const activeAccount = instance.getActiveAccount();
          if (!activeAccount) {
            instance.setActiveAccount(accounts[0]);
          }

          const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          });
          setToken(response.accessToken);
          //  token_email = activeAccount.idTokenClaims.email
          setTokenEmail(activeAccount.idTokenClaims.email)
          console.log("Ttoken_email: ", token_email);
          setAccountsMsal(activeAccount.idTokenClaims.email)

          console.log("Token acquired silently: ", response.accessToken);


        } else {

          loginAttempted.current = true;
          const loginResponse = await instance.loginRedirect(loginRequest).catch(error => {
            if (error.errorCode === 'user_cancelled') {
              popupClosed.current = true;
            }
            throw error;
          });
          setToken(loginResponse.accessToken);



          loginAttempted.current = false;
          popupClosed.current = false; // Reset the flag after successful login
        }
      } catch (error) {
        console.log("Failed to acquire token silently:", error);
        setError("Authentication failed. Please try again.");
        loginAttempted.current = false; // Reset the login attempt flag
      } finally {
        setInteractionInProgress(false); // Reset interaction flag
      }
    };

    handleLogin();
  }, [instance, accounts, interactionInProgress, inProgress]);

  async function fetchData(url,cnfg) {
    try {
      const account = accounts[0];
      if (!account) {
        console.log("No account found");
        return;
      }



      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account,
      });

       //assume GET method if not specified
      cnfg.method ||= "GET";
       
      //if this request is to the Integration Server, add our Access Token as the Bearer Token
       cnfg.headers.Authorization = `Bearer ${response.accessToken}`;
       cnfg.url = `${process.env.REACT_APP_WEBSITE_NAMES_AZURE}${url}`;
       return axios.request(cnfg);

    } catch (error) {
      console.log("Failed to fetch data:", error);
      throw error; // Rethrow the error for the caller to handle
    }
  };


  async function postData(url,formData,cnfg) {
    try {
      const account = accounts[0];
      if (!account) {
        console.log("No account found");
        return;
      }

      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account,
      });
      
       
      //if this request is to the Integration Server, add our Access Token as the Bearer Token
       cnfg.headers.Authorization = `Bearer ${response.accessToken}`; 
       return axios.post(`${process.env.REACT_APP_WEBSITE_NAMES_AZURE}${url}`,formData,cnfg);

    } catch (error) {
      console.log("Failed to post data:", error);
      throw error; // Rethrow the error for the caller to handle
    }
  };

  async function request(url,cnfg) {
    try {
      const account = accounts[0];
      if (!account) {
        console.log("No account found");
        return;
      }

      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account,
      });
      
       
      //if this request is to the Integration Server, add our Access Token as the Bearer Token
       cnfg.headers.Authorization = `Bearer ${response.accessToken}`; 
       cnfg.url =`${process.env.REACT_APP_WEBSITE_NAMES_AZURE}${url}`;
       return axios.request(cnfg);

    } catch (error) {
      console.log("Failed to post data:", error);
      throw error; // Rethrow the error for the caller to handle
    }
  };


  const logout = async () => {
    try {
      await instance.logoutRedirect({
        postLogoutRedirectUri: window.location.href,
      });
      setToken(null);
      console.log("User logged out and session cleared");
    } catch (error) {
      console.log("Error during logout: ", error);
      setError(error.message || "Logout failed");
    }
  };


  return { token, fetchData, postData, request, error, logout };

};

export default useAuth;
