import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import useAuth from '../azureLogin/useAuth';

const MissingRequirementsModal = ({ show, handleClose, applicationID}) => {
  const { t } = useTranslation();  
  const [requirements, setRequirements] = useState([]);
  const isForMobileDevice = true;  
  const [error, setError] = useState(null);
  const { token,request } = useAuth();
  //All requirements
  
  useEffect(() => {
    const fetchMissingReqData = async () => {      

      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        headers: {
          'accept': 'application/json'
        }
      };

      try {
        const url= `/AvailableApplication/${applicationID}/Requirements?status=missing&fields=Name%2CCreatedById%2CLastModifiedById%2CStatus__c%2CAvailableApplication__c%2CData_Doc_Comments__c%2Cshow_copies_of_diploma_upload_button__c%2CAcceptance_Criteria_Comments__c%2CRequirement_Percentage__c%2CRequirement_Category__c%2CRejection_Text__c%2CName_of_Requirement__c%2CELP_Testing_Waiver__c%2CApplicant_Request_Waiver__c%2CLanguage_Proficiency_Exam__c.I_Understand_Test_Scores__c%2Cshow_Edu_Upload_button__c%2CData_Doc__c.Document_Received__c%2CLink_to_form__c%2CApplicant_to_re_generate_form__c%2CRequirement_Category__c.Category_Type__c%2CRequirement_Type__c%2CAva_App_Portal_Code__c%2CRequirementCategory.Category_Type__c`;
       
       // debugger;
        const response = await request(url, config);
        setRequirements(response.data);
        console.log(response.data);
      } catch (error) {
        setError(error);
      }
    };

    fetchMissingReqData();
  }, [applicationID, token]);


  const getFurtherActionsRequired = (req) => {
        
    if (req.Ava_App_Portal_Code__c === 'CGFNS-AP') {
        if (req.Status__c.toLowerCase().includes('documentation needed') || req.Status__c.toLowerCase().includes('waiting for additional documentation')) {
            if (req.Requirement_Type__c.toLowerCase().includes('language proficiency')) {
                return t('LP_Documentation_Needed_Message');
            } else {
                return t('LP_Documentation_Needed_Message');
            }
        }
        if (req.Status__c.toLowerCase().includes('meets requirement')) {
            return t('Meets_Requirement_Message');
        }
        if (req.Status__c.toLowerCase().includes('documentation ready for evaluation')) {
            return t('Documentation_Ready_for_Evaluation');
        }
        if (req.Status__c.toLowerCase().includes('waiting for authentication')) {
            return t('Waiting_for_Authentication');
        }
    } else if (req.Ava_App_Portal_Code__c === 'ICD') {
        if (req.Status__c.toLowerCase().includes('documentation needed') || req.Status__c.toLowerCase().includes('waiting for additional documentation')) {
            if (req.Requirement_Type__c.toLowerCase().includes('language proficiency')) {
                return t('ICD_LP_Documentation_Needed_Message');
            } else {
                return t('ICD_Documentation_Needed_Message');
            }
        }
        if (req.Status__c.toLowerCase().includes('meets requirement')) {
            return t('ICD_Meets_Requirement_Message');
        }
        if (req.Status__c.toLowerCase().includes('documentation ready for evaluation')) {
            return t('ICD_Documentation_Ready_for_Evaluation');
        }
        if (req.Status__c.toLowerCase().includes('waiting for authentication')) {
            return t('ICD_Waiting_for_Authentication');
        }
    }

    return '';
};

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t('Missing_Requirement')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isForMobileDevice ? (
          <>
            <div className="container">
              <div className="row">
                <div className="col-md-3">{t('Category_app_tracker')}</div>
                <div className="col-md-3">{t('Requirement_app_tracker')}</div>
                <div className="col-md-3">{t('Status')}</div>
                <div className="col-md-3">{t('Further_actions_app_tracker')}</div>
              </div>
              {requirements.length ? (
                requirements.map((req, index) => (
                  <div className="row" key={index}>
                    <div className="col-md-3">{req.RequirementCategory.Category_Type__c}</div>
                    <div className="col-md-3">{req.Name}</div>
                    <div className="col-md-3">{req.Status__c}</div>
                    <div className="col-md-3">
                      {req.Acceptance_Criteria_Comments__c ? (
                        <p>{req.Acceptance_Criteria_Comments__c}</p>
                      ) : (
                        req.Status__c === 'Documentation Needed' &&
                        (req.RequirementCategory.Category_Type__c === 'Identification Document' ? (
                          <p>{t('CGFNS_Doc_not_received')}</p>
                        ) : (
                          <p>{getFurtherActionsRequired(req)}</p>
                        ))
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="row">
                  <div className="col-md-3">N/A</div>
                  <div className="col-md-3">N/A</div>
                  <div className="col-md-3">N/A</div>
                  <div className="col-md-3">N/A</div>
                </div>
              )}
            </div>
          </>
        ) : (
          <div style={{ overflow: 'scroll' }}>
            {requirements.length >0 ? (
              requirements.map((req, index) => (
                <table bordered key={index}>
                  <tbody>
                    <tr>
                      <th  style={{ width: '40%' }}>{t('Category_app_tracker')}</th>
                      <td>{req.RequirementCategory.Category_Type__c}</td>
                    </tr>
                    <tr>
                      <th  style={{ width: '40%' }}>{t('Status')}</th>
                      <td>{req.Name}</td>
                    </tr>
                    <tr>
                      <th  style={{ width: '40%' }}>{t('Requirement_app_tracker')}</th>
                      <td>{req.Status__c}</td>
                    </tr>
                    <tr>
                      <th  style={{ width: '40%' }}>{t('Further_actions_app_tracker')}</th>
                      <td>
                        {req.Acceptance_Criteria_Comments__c ? (
                          <p>{req.Acceptance_Criteria_Comments__c}</p>
                        ) : (
                          req.Status__c === 'Documentation Needed' &&
                          (req.RequirementCategory.Category_Type__c === 'Identification Document' ? (
                            <p>{t('CGFNS_Doc_not_received')}</p>
                          ) : (
                            <p>{getFurtherActionsRequired(req)}</p>
                          ))
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              ))
            ) : (
              <div style={{ backgroundColor: 'white' }}>N/A</div>
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t('Close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MissingRequirementsModal;
