import React,{useState,useEffect} from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import useAuth from '../azureLogin/useAuth';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { useLocation } from 'react-router-dom';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const formatDate = (isoString) => {
    const date = new Date(isoString);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };
  
  const formatAmount = (amount) => {
    if (amount === null) return '';
    const isNegative = amount < 0;
    const absoluteAmount = Math.abs(amount);
    return isNegative ? `-$${absoluteAmount}` : `$${absoluteAmount}`;
  };
  
  
  const OrderHistory = () => {
    const { t, i18n } = useTranslation();
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const { token,fetchData } = useAuth();
    
  const query = useQuery();
  const isICD = query.get('isICD');
   
    const toggleLanguage = () => {
      const newLang = i18n.language === 'en' ? 'fr' : 'en';
      i18n.changeLanguage(newLang);
    };
  
    useEffect(() => {
      const fetchDetails = async () => {
        
  
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          headers: {
            'accept': 'application/json'
          }
        };
  
        try {
          
          const url = `/Contact/me/BalanceLineItems?fields=Amount__c%2CApp_Program_Name__c%2CCreatedDate%2CId%2CName%2CType__c`;
          
          const response = await fetchData(url,config);
          setData(response?.data);
        } catch (error) {
          setError(error);
        }
      };
  
      fetchDetails();
    }, [token]);
  
    if (error) {
      return <div>Error: {error.message}</div>;
    }
  
    if (!data) {
      return <div>Loading...</div>;
    }
    const sortedData = [...data].sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate));
  
    return (
      <>
    <AuthenticatedTemplate>
        <div>          
        {isICD==="true" && (<button onClick={toggleLanguage} className='language-button'>
          {i18n.language === 'en' ? t('view in Français') : t('view in English')}
        </button>)}
            <h1 className="heading-orderhistory">{t('Order_History')}</h1>
            <p className='historydetails'>{t('Order_History_Text')}</p>
            <div className="table-container">
              <table className="table-order-history">
                <thead>
                  <tr>
                    <th>{t('Dialpad__Dialpad_Created_Date')}</th>
                    <th>{t('App_Program_Name')}</th>
                    <th>{t('Amount')}</th>
                    <th>{t('APXTConga4__Recipient_Type')}</th>
                  </tr>
                </thead>
                <tbody>
        {sortedData.map((order, index) => (
          <tr key={index}>
            <td>{formatDate(order.CreatedDate)}</td>
            <td>{order.App_Program_Name__c}</td>
            <td>{formatAmount(order.Amount__c)}</td>
            <td>{order.Type__c}</td>
          </tr>
        ))}
      </tbody>
              </table>
            </div>
          </div>
          </AuthenticatedTemplate>
      </>
    );
  };
  
  export default OrderHistory;