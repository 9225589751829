import { useState } from "react";

const OrderView = ({ order }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const validateOrder = () => {
    if (!order || parseFloat(order?.lineBalance) <= 0) {
      return "Payment cannot be processed. No outstanding balance.";
    }

    if (!order?.Contact?.FirstName || !order?.Contact?.LastName) {
      return "Applicant name is missing. Please ensure all order details are correct.";
    }

    if (!order?.orderNumber) {
      return "Order number is missing. Cannot proceed with payment.";
    }

    if (order?.isExpired) {
      return "This order has expired and cannot be paid.";
    }

    return null;
  };

  const handlePaymentClick = (paymentMethod) => {
    const validationError = validateOrder();
    if (validationError) {
      setErrorMessage(validationError);
      return;
    }

    setErrorMessage("");
    setIsProcessing(true);

    if (paymentMethod === "PayPal") {
      console.log("PayPal payment initiated");
      setTimeout(() => setIsProcessing(false), 2000);
    } else if (paymentMethod === "PayLater") {
      console.log("Pay Later payment initiated");
      setTimeout(() => setIsProcessing(false), 2000);
    }
  };

  return order ? (
    <div className="container mt-4">
      {[
        { label: "Applicant Name", value: `${order?.Contact?.FirstName ?? ""} ${order?.Contact?.LastName ?? ""}` },
        { label: "Program", value: order?.orderCode && order?.orderType
            ? order.orderType.toUpperCase().includes(order.orderCode.toUpperCase())
              ? order.orderType
              : `${order.orderCode}: ${order.orderType}`
            : order?.orderType || order?.orderCode || "" },
        { label: "Order Number", value: order?.orderNumber ?? "" },
        { label: "Amount Due", value: `$${order?.lineBalance ? parseFloat(order.lineBalance).toFixed(2) : "0.00"}` },
      ].map((item, index) => (
        <div className="row mb-2" key={index}>
          <div
            className="col-12"
            style={{
              display: "flex",
              justifyContent: "flex-start", 
              alignItems: "left",
              gap: "8px", 
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                minWidth: "130px", 
                textAlign: "left", 
                paddingRight: "5px", 
              }}
            >
              {item.label}
            </div>
            <div
              style={{
                minWidth: "1px", 
                textAlign: "left", 
              }}
            >
              :
            </div>
            <div
              style={{
                flex: "1", 
                textAlign: "left", 
              }}
            >
              {item.value}
            </div>
          </div>
        </div>
      ))}

      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

      {isProcessing && <div className="alert alert-info">Processing payment, please wait...</div>}
    </div>
  ) : (
    <div>Loading Order...</div>
  );
};

export default OrderView;